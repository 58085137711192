* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  --dark-gray: rgb(30, 30, 30);
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--dark-gray);
  background-image: url('https://d17jmxltsx3ffm.cloudfront.net/sams-nature-photography-background.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1.1px;
}

body * {
  z-index: 10;
}

.background-overlay {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: auto;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
}

h1, h2, h3, h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 2rem;
}

a,
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:active,
.navbar-light .navbar-brand:visited,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link:visited {
  color: white;
}

.navbar-light .navbar-brand:focus {
  outline: none;
}

.nav-link.active {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.navbar-light {
  margin-left: calc(5% - 1rem);
  margin-right: calc(5% - 1rem);
}

.navbar-light .navbar-brand {
  margin-top: 1rem;
  text-align: left;
}

.navbar-light .navbar-brand h4 {
  margin-bottom: 0px;
}

.navbar-light .navbar-brand p {
  font-size: 1rem;
}

.navbar-nav a {
  padding-top: 0px;
}

.navbar-toggler.collapsed {
  border-color: white;
  border-radius: 0;
}

.navbar-toggler:focus {
  outline: none;
  border: solid 1px white;
  border-radius: 0;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-content {
  padding: 5%;
  min-height: calc(100% - 120px);
  margin-bottom: -80px;
}

.page-content:after {
  content: "";
  display: block;
  height: 80px;
}

.page-content h2 {
  margin-bottom: 2rem;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.photo {
  position: relative;
  width: 20%;
  min-width: 300px;
  margin: 20px;
  height: 20%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px white;
  color: white;
}

.photo h4 {
  padding: 1rem;
}

.photo:focus,
.photo:active,
.photo:visited {
  text-decoration: none;
  color: white;
}

.photo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.gallery-img {
  filter: brightness(0.6);
}

.about {
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
}

.contact-form {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.contact-form-intro {
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

.about-photo {
  width: calc(50% - 15px);
  border: solid 1px white;
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;
}

.bio {
  text-align: left;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-dialog {
  max-width: none;
  max-height: none;
}

.modal-content,
.enlarged-photo-with-details,
.enlarged-photo {
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.enlarged-photo-with-details {
  border: solid 2px white;
}

.enlarged-photo-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-details {
  opacity: 0;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(30, 30, 30, 0.7);
  padding: 20px;
  border-left: solid 2px white;
  border-right: solid 2px white;
  border-bottom: solid 2px white;
  max-height: 100%;
  overflow: auto;
}

.photo-details.visible {
  opacity: 1;
}

.photo-details h4,
.photo-details p {
  margin-bottom: 0;
  margin-left: 2rem;
  margin-right: 2rem;
}

.photo-details p {
  margin-top: 10px;
}

.photo-details-icon {
  position: absolute;
  bottom: .75rem;
  right: .75rem;
  font-size: 1.25rem;
}

.previous,
.next,
.exit {
  position: fixed;
  width: 25px;
  height: 25px;
}

.previous,
.next {
  top: calc(50% - 15px);
}

.previous {
  left: 15px;
}

.next {
  right: 15px;
}

.exit {
  top: 15px;
  right: 15px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group,
.form-group input,
.form-group textarea,
.contact-form form button {
  border-radius: 0px;
  letter-spacing: 1.1px;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--dark-gray);
  box-shadow: 0 0 0 0.2rem var(--dark-gray);
}

.contact-form form button {
  text-transform: uppercase;
  margin-top: 30px;
}

.footer {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer p {
  font-size: 14px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .about-photo {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

@media (hover: hover) {
  a:hover,
  .navbar-light .navbar-brand:hover,
  .navbar-light .navbar-nav .nav-link:hover {
    color: white;
  }

  .photo:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }

  .photo-details {
    opacity: 0;
  }

  .enlarged-photo-with-details:hover .photo-details {
    opacity: 1;
  }

  .photo-details-icon {
    display: none;
  }

  .previous:hover,
  .next:hover,
  .exit:hover {
    cursor: pointer;
  }

  .footer a:hover {
    text-decoration: none;
  }
}
